/* styles.css */

/* Always set the map height explicitly to define the size of the div element
 * that contains the map. */
 #map {
  height: 100%;
}

/* Optional: Makes the sample page fill the window. */
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.master-menu {
  position: absolute;
  bottom: 1em;
  left: 1em;
  height: auto;
  width: 90%;
  max-width: 300px;
  background-color: #fff;
  padding: 5px;
  border: 1px solid #999;
  font-family: Arial, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}

@media (min-width: 768px) {
  .master-menu {
    left: 2em;
    width: 40%;
    max-width: 400px;
    font-size: 12px;
  }
}

@media (min-width: 992px) {
  .master-menu {
    left: 10em;
    width: 30%;
    max-width: none;
    font-size: 12px;
  }
}

/* styles.css */

.gm-scaled-control-container {
  transform: scale(1.5);
  transform-origin: 0 0;
}

@media (min-width: 768px) {
  .gm-scaled-control-container {
    transform: scale(1.7);
  }
}

.custom-drawing-controls {
  display: flex;
  flex-direction: column;
  gap: 5px;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}

.custom-button {
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 3px;
  cursor: pointer;
  font-size: 14px;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  outline: none;
  transition: background-color 0.3s;
}

.custom-button:hover {
  background-color: #e6e6e6;
}
