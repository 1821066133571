#map {
  height: 100%;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.master-menu {
  height: auto;
  width: 90%;
  max-width: 300px;
  background-color: #fff;
  border: 1px solid #999;
  border-radius: 4px;
  padding: 5px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  position: absolute;
  bottom: 1em;
  left: 1em;
  box-shadow: 0 2px 6px #0000004d;
}

@media (min-width: 768px) {
  .master-menu {
    width: 40%;
    max-width: 400px;
    font-size: 12px;
    left: 2em;
  }
}

@media (min-width: 992px) {
  .master-menu {
    width: 30%;
    max-width: none;
    font-size: 12px;
    left: 10em;
  }
}

.gm-scaled-control-container {
  transform-origin: 0 0;
  transform: scale(1.5);
}

@media (min-width: 768px) {
  .gm-scaled-control-container {
    transform: scale(1.7);
  }
}

.custom-drawing-controls {
  background-color: #fffc;
  border-radius: 5px;
  flex-direction: column;
  gap: 5px;
  padding: 5px;
  display: flex;
  box-shadow: 0 2px 6px #0000004d;
}

.custom-button {
  cursor: pointer;
  text-align: center;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
  outline: none;
  padding: 5px 10px;
  font-size: 14px;
  text-decoration: none;
  transition: background-color .3s;
}

.custom-button:hover {
  background-color: #e6e6e6;
}

/*# sourceMappingURL=index.c945c0d6.css.map */
